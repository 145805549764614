<!-- 付款 -- 申请、审批、发放 组件 -->
<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        {{ getTitle }}
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" id="Scrollbar">
      <div class="fullScreenMainCon">
        <!-- 申请单 begin -->
        <el-form class="form_box" ref="applyForm" :model="applyForm" :rules="applyFormRules">
          <el-descriptions
            style="width: 100%"
            direction="vertical"
            :column="options.list_s == 0 ? 5 : 6"
            border
          >
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              label="单据编号"
              v-if="options.list_s != 0"
              prop="expenseNumber"
              ref="expenseNumber"
            >
              <el-form-item prop="expenseNumber" ref="expenseNumber">
                {{ applyForm.expenseNumber }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              label="申请人"
            >
              <el-form-item prop="createName">
                {{ applyForm.createName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              label="申请日期"
            >
              <el-form-item prop="taskDate">
                {{ applyForm.taskDate | dateFormat }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              label="部门"
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
            >
              <el-form-item prop="deptId">
                {{ applyForm.deptName }}
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              :span="2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                申请单位
              </template>
              <el-form-item prop="companyType">
                <Dictionary
                  v-if="!isSee"
                  :disabled="isSee"
                  :clearable="false"
                  v-model="applyForm.companyType"
                  code="COMPANY_TYPE"
                  placeholder="请选择申请单位"
                />
                <span v-else>
                  {{ applyForm.companyType | dict(companyTypeList) }}
                </span>
              </el-form-item>
            </el-descriptions-item>
            <!-- <el-descriptions-item
              label-class-name="businessOutSource"
              content-class-name="businessOutSource"
            >
              <template slot="label">
                <span style="color: #000">是否为外协费用</span>
              </template>
              <template v-if="!isSee">
                <el-radio v-model="applyForm.businessOutSource" label="HAVE">是</el-radio>
                <el-radio v-model="applyForm.businessOutSource" label="NOTHAVE">否</el-radio>
              </template>
              <template v-else>{{ applyForm.businessOutSource === 'HAVE' ? '是' : '否' }}</template>
            </el-descriptions-item> -->

            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                开户行
              </template>
              <el-form-item prop="openingBank">
                <el-tooltip
                  v-if="!isSee"
                  effect="dark"
                  :disabled="openingBankTooltip"
                  :content="applyForm.openingBank"
                  placement="top"
                >
                  <el-input
                    v-model="applyForm.openingBank"
                    placeholder="请输入开户行"
                    style="width: 100%"
                    :disabled="isSee"
                    @input="$forceUpdate()"
                  >
                  </el-input>
                </el-tooltip>
                <span v-else>{{ applyForm.openingBank }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
            >
              <template slot="label">
                <span style="color: red">*</span>
                银行账号
              </template>
              <el-form-item prop="bankAccount">
                <el-input
                  v-if="!isSee"
                  @input="inputFn('bankAccount')"
                  v-model="applyForm.bankAccount"
                  placeholder="请输入银行账号"
                  style="width: 100%"
                  :disabled="isSee"
                >
                </el-input>
                <span v-else>{{ applyForm.bankAccount }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              :span="options.list_s == 0 ? 2 : 2"
            >
              <template slot="label">
                <span style="color: red">*</span>
                收款单位
                <el-button
                  v-if="!isSee"
                  style="margin-left: 15px"
                  size="mini"
                  type="warning"
                  @click="isPartnerShow = true"
                >
                  历史单位
                </el-button>
              </template>
              <el-form-item prop="collectionUnit">
                <el-input
                  v-if="!isSee"
                  type="textarea"
                  autosize
                  :disabled="isSee"
                  v-model="applyForm.collectionUnit"
                  @input="$forceUpdate()"
                  placeholder="请输入收款单位"
                >
                </el-input>
                <span v-else>{{ applyForm.collectionUnit }}</span>
              </el-form-item>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              :span="1"
              v-if="options.list_s != 0"
              label="是否优先发放"
            >
              <el-radio
                :disabled="!applyForm.isDisplayEditButton"
                v-model="applyForm.priority"
                :label="1"
                >是</el-radio
              >
              <el-radio
                :disabled="!applyForm.isDisplayEditButton"
                v-model="applyForm.priority"
                :label="0"
                >否</el-radio
              >
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="businessOutSource outsourceLabelClassName"
              content-class-name="businessOutSource outsourceContentClassName"
            >
              <template slot="label">
                <span style="color: #000">费用类别</span>
              </template>
              <span style="color: #000; font-size: 17px">外协费用</span>
            </el-descriptions-item>
            <el-descriptions-item
              labelClassName="outsourceLabelClassName"
              contentClassName="outsourceContentClassName"
              label="付款原因"
              :span="6"
            >
              <template slot="label">
                <span style="color: red">*</span>
                付款原因
              </template>
              <el-form-item prop="paymentCause" style="width: 100%">
                <el-input
                  v-if="!isSee"
                  type="textarea"
                  style="width: 100%"
                  v-model="applyForm.paymentCause"
                  placeholder="请输入付款原因"
                  :disabled="isSee"
                >
                </el-input>
                <el-input
                  v-else
                  type="textarea"
                  style="width: 100%"
                  v-model="applyForm.paymentCause"
                  placeholder="请输入付款原因"
                  :disabled="!applyForm.isDisplayEditButton"
                >
                </el-input>
              </el-form-item>
            </el-descriptions-item>
          </el-descriptions>
        </el-form>
        <el-descriptions style="margin-top: 10px" title="" :column="3" border>
          <el-descriptions-item labelStyle="width:100px;">
            <template slot="label">
              <span style="color: red">*</span>
              商务名称
            </template>
            <template v-if="isSee">
              <span style="display: inline-block; line-height: 40px; width: 70%">{{
                applyForm.businessName
              }}</span>
            </template>
            <template v-else>
              <el-select
                style="width: 60%"
                @visible-change="onBusiness"
                v-model="applyForm.businessManageId"
                :disabled="isSee"
                clearable
                filterable
                placeholder="请选择商务"
              >
                <el-option
                  v-for="(item, index) in businessList"
                  :key="index"
                  v-show="!selectIdsArr.includes(item.id)"
                  :label="item.businessName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </template>
            <el-button style="margin-left: 25px" v-if="!isSee" type="primary" @click="addRow(1)">
              添加空白明细
            </el-button>
            <el-button
              style="margin-left: 25px"
              v-if="!isSee"
              type="primary"
              plain
              @click="addRow(2)"
            >
              复制上一条科目
            </el-button>

            <div style="color: red" v-if="!applyForm.businessManageId && status == 1">
              请选择商务
            </div>
            <el-button
              style="float: right"
              type="success"
              v-if="applyForm.status == 10 || applyForm.status == 100"
              @click="exportFileZipFn"
              :loading="loading"
            >
              全部附件下载
            </el-button>
          </el-descriptions-item>
        </el-descriptions>
        <el-table
          :data="applyForm.detailList"
          :key="tableIndex"
          :header-cell-class-name="headerInvoiceClassName"
          id="table"
          border
          :row-style="{ height: '80px' }"
        >
          <!-- 删除 -->
          <el-table-column label="操作" width="50">
            <template slot-scope="scope">
              <i
                v-if="!isSee"
                style="font-size: 28px"
                class="el-icon-remove-outline iconCost"
                @click="handleRemove(scope.$index, scope.row)"
              ></i>
            </template>
          </el-table-column>

          <el-table-column
            prop="typeOne"
            :show-overflow-tooltip="false"
            label="费用科目一"
            width="160"
          >
            <template slot="header" slot-scope="scope">
              <span style="color: red">*</span> 费用科目一
              <div>
                <el-button
                  size="mini"
                  type="warning"
                  :loading="typeDialogLoading"
                  @click="isDialogShow = true"
                  >科目说明</el-button
                >
              </div>
            </template>
            <template slot-scope="scope">
              <Dictionary
                :disabled="isSee"
                :filterable="true"
                :clearable="false"
                v-model="scope.row.typeOne"
                code="CBFYZC"
                @change="valueChange($event, scope.row, 1)"
                @optionsChange="requiredTwo($event, scope.row, 1)"
                :placeholder="isSee ? '' : '请选择费用科目一'"
              />
              <div class="color_r" v-if="!scope.row.typeOne && status == 1">费用科目一必填</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTwo"
            :show-overflow-tooltip="false"
            label="费用科目二"
            width="160"
            :render-header="addRedStar"
          >
            <template slot-scope="scope">
              <Dictionary
                :disabled="isSee"
                v-model="scope.row.typeTwo"
                :code="scope.row.typeOne"
                :clearable="false"
                :disabledSelect="true"
                :placeholder="isSee ? '' : '请选择费用科目二'"
                @change="valueChange($event, scope.row, 2)"
                @optionsChange="requiredTwo($event, scope.row, 2)"
              />
              <div
                class="color_r"
                v-show="
                  (scope.row.rulesTips && !scope.row.typeTwo) ||
                  (scope.row.rulesTips && rulesTips == 1 && !scope.row.typeTwo)
                "
              >
                费用科目二有值必填
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeThree"
            :show-overflow-tooltip="false"
            label="费用科目三"
            width="160"
            :render-header="addRedStar"
          >
            <template slot-scope="scope">
              <Dictionary
                :disabled="isSee"
                v-model="scope.row.typeThree"
                :code="scope.row.typeTwo"
                :clearable="false"
                :placeholder="isSee ? '' : '请选择费用科目三'"
                @change="valueChange($event, scope.row, 3)"
                @optionsChange="requiredTwo($event, scope.row, 3)"
              />
              <div
                class="color_r"
                v-show="
                  (scope.row.rulesTips2 && !scope.row.typeThree) ||
                  (scope.row.rulesTips2 && rulesTips2 == 1 && !scope.row.typeThree)
                "
              >
                费用科目三有值必填
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="cost" label="金额 (元)" width="120">
            <template slot="header"> <span style="color: red">*</span>金额 (元) </template>
            <template slot-scope="scope">
              <span v-if="isSee">{{ scope.row.cost | applyAmount }}</span>

              <el-input
                v-else
                @blur="blurInput(scope.$index)"
                type="number"
                class="numrule"
                placeholder="请输入"
                v-model="scope.row.cost"
              >
              </el-input>
              <div style="color: red" v-if="!scope.row.cost && status == 1">金额不能为零</div>
            </template>
          </el-table-column>
          <el-table-column
            scoped-slot
            prop="invoiceStatus"
            :show-overflow-tooltip="false"
            :label="'事由附件详细说明\n（含参与人员）'"
            width="250"
          >
            <template slot="header">
              <span style="color: red">*</span>事由附件详细说明（含参与人员）
            </template>
            <template slot-scope="scope">
              <span v-if="isSee">
                <el-input
                  :disabled="!applyForm.isDisplayEditButton"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 999 }"
                  v-model="scope.row.invoiceStatus"
                ></el-input
              ></span>
              <el-input
                v-else
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 999 }"
                v-model="scope.row.invoiceStatus"
              ></el-input>
              <div style="color: red" v-if="!scope.row.invoiceStatus && status == 1">
                事由附件详细说明必填
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            min-width="340"
            label="发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot="header">
              <div>发票附件上传</div>
              <div>只能上传pdf或图片.png .jpg .jpeg格式</div>
            </template>
            <template slot-scope="scope">
              <div class="upload-center">
                <UploadFileToinvoice
                  v-if="!isSee"
                  :type="type"
                  :fields="fields"
                  @click.native="uploadIndex(scope.$index)"
                  @uploadFileToinvoice="invoiceUpload"
                ></UploadFileToinvoice>
              </div>
              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                class="invoice-file"
              >
                <template v-if="n.fileType === 'INVOICE'">
                  <div class="invoice-file-left">
                    <div>
                      <a
                        v-if="isShowType(n)"
                        style="text-decoration: revert; color: #409eff; padding-right: 8px"
                        target="_blank"
                        :href="`${filepath}${n.filePath}`"
                      >
                        {{ n.fileName }}
                      </a>
                      <span v-else class="img_txt" @click="imgIsShow(n, index)">{{
                        n.fileName
                      }}</span>
                    </div>
                    <div>
                      <span style="color: red" v-if="n.fileInvoiceNoType === 'EXIST'"
                        >发票识别存疑</span
                      >
                      <!-- <span style="color: red" v-else>编码：{{ n.invoiceNo }}</span> -->
                    </div>
                  </div>
                  <i
                    @click="del(index, scope.$index, n)"
                    v-show="!isSee"
                    class="iconfont iconerror iconCost"
                  ></i>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="left"
            width="330"
            label="非发票附件上传"
            :show-overflow-tooltip="false"
          >
            <template slot-scope="scope">
              <div class="upload-center">
                <Upload
                  v-if="!isSee"
                  :type="type"
                  :fields="fields"
                  @click.native="uploadIndex(scope.$index)"
                  @upload="upload"
                ></Upload>
              </div>

              <div
                v-for="(n, index) in scope.row.expenseFileList"
                :key="index"
                style="margin-top: 5px"
              >
                <template v-if="n.fileType === 'NORMAL'">
                  <a
                    v-if="isShowType(n)"
                    style="text-decoration: revert; color: #409eff; padding-right: 8px"
                    target="_blank"
                    :href="`${filepath}${n.filePath}`"
                  >
                    {{ n.fileName }}
                  </a>
                  <span v-else class="img_txt" @click="imgIsShow(n, index)">{{ n.fileName }}</span>

                  <i
                    v-show="!isSee"
                    @click="del(index, scope.$index, n)"
                    class="iconfont iconerror iconCost"
                  ></i
                ></template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-descriptions :column="2" border>
          <el-descriptions-item label="合计" labelStyle="width:10%">
            {{ amountTo | applyAmount }} 元
          </el-descriptions-item>
          <el-descriptions-item label="金额大写" labelStyle="width:10%">
            {{ amountTo | numberParseChina }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions
          :column="1"
          direction="vertical"
          style="margin-top: 10px"
          border
          v-if="applyForm.remark"
        >
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 财务确认发放备注 </span>
            </template>
            {{ applyForm.remark }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span style="color: #0066ff; font-size: 18px"> 发放附件 </span>
            </template>
            <template v-for="item in affirmCostFileList">
              <a
                :key="item.id"
                target="_blank"
                style="text-decoration: revert; color: #409eff; display: inline-block; width: 31%"
                :href="`${filepath}${item.filePath}`"
                >{{ item.fileName }}</a
              >
            </template>
          </el-descriptions-item>
        </el-descriptions>

        <div v-if="bidIsShow" style="margin: 36px 0">
          <div>
            <span style="color: red; font-size: 18px; margin-right: 5px">*</span>
            <span style="color: #000; font-size: 18px; margin: 5px 10px 0 0; font-weight: 700"
              >投标信息</span
            >
            <el-button type="success" v-if="!isSee" @click="bidDialog = true">
              选择关联投标信息
            </el-button>
          </div>
          <el-table :data="bidTable" border max-height="300">
            <el-table-column
              align="center"
              prop="bidName"
              min-width="280"
              :show-overflow-tooltip="false"
              label="标的名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="bidType"
              width="100"
              :show-overflow-tooltip="false"
              label="标的类型"
            >
              <template slot-scope="scope">
                {{ scope.row.bidType | dict(bidType) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              width="70"
              :show-overflow-tooltip="false"
              prop="bidHostEscort"
              label="是否重点关注"
            >
              <template slot-scope="scope">
                {{ scope.row.bidHostEscort == 'IS_FLAG' ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="155"
              sortable
              prop="bidOpeningTime"
              label="开标时间"
            >
              <template slot-scope="scope">
                {{ scope.row.bidOpeningTime | timeFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="160"
              prop="bidOwnerUnit"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="bidAgency"
              label="代理\招标单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="160"
              :show-overflow-tooltip="false"
              prop="bidWinningAmount"
              label="中标金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.bidWinningAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="bidStatus"
              label="状态"
              width="110"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.bidStatus | dict(bidStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              :show-overflow-tooltip="false"
              align="center"
              fixed="right"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="options.list_s == 4 && permission(['BAIL_UPDATE'])"
                  style="background-color: #7c9cec; color: #fff"
                  size="mini"
                  @click="onBidEditFn"
                  >保证金修改</el-button
                >
                <el-button type="primary" v-if="!isSee" size="mini" @click="bidDialog = true"
                  >更换</el-button
                >
                <el-button type="danger" v-if="!isSee" size="mini" @click="deleteBid"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <OpinionArea
          ref="opinionArea"
          :procInstId="options.processInstId"
          :title="'外协费用申请进度'"
          :instInvolved="instInvolved"
        ></OpinionArea>
        <!-- 申请单 end -->
      </div>

      <div class="fullScreenOperation shadow">
        <el-button
          v-if="isApply && applyForm.status != 10"
          class="teal_bg"
          type="success"
          @click="handleSubmit(1)"
          >提交</el-button
        >
        <el-button
          v-if="isApply && applyForm.status != 10"
          type="success"
          @click="handleSubmit(0)"
          >{{ options.id ? '保存' : '暂存' }}</el-button
        >
        <el-button v-if="applyForm.isDisplayEditButton" type="warning" @click="handleSubmit(0)"
          >编辑</el-button
        >
        <el-button
          v-if="
            options.list_s == 1 &&
            applyForm.status == 10 &&
            options.export != 1 &&
            applyForm.taskDefinitionKey != 'CASHIER'
          "
          type="success"
          @click="rejectFn"
          >撤回</el-button
        >
        <el-button v-if="isExamine" type="success" @click="handleAdopt">通过</el-button>
        <el-button v-if="isExamine" type="danger" @click="handleFail">不通过</el-button>

        <SelectDialog
          v-if="isExamine && applyForm.status == 10 && permission(['FLOW_HAND_OVER'])"
          @selectDataChange="selectAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary"> 转审 </el-button>
        </SelectDialog>
        <el-button v-if="isGrant && options.status != 100" @click="affirmCost" type="primary"
          >确认发放</el-button
        >
        <el-button
          type="warning"
          v-if="
            options.list_s == 3 ||
            options.list_s == 4 ||
            options.export == 1 ||
            applyForm.status > 5
          "
          @click="printFn"
          >预览打印</el-button
        >
        <el-button
          v-if="
            isGrant ||
            options.list_s == 3 ||
            options.list_s == 4 ||
            options.export == 1 ||
            applyForm.status > 5
          "
          type="success"
          @click="exportFn"
          >导出</el-button
        >
        <SelectDialog
          v-if="options.list_s == 4 && applyForm.status == 10 && permission(['ADD_PUBLIC_AGENT'])"
          @selectDataChange="selectAddAuditor"
          style="display: inline-block"
        >
          <el-button slot="button" type="primary"> 增加待办人 </el-button>
        </SelectDialog>
        <el-button type="info" @click="returnFn">返回</el-button>
      </div>
    </div>

    <el-dialog append-to-body :visible.sync="isDialog" width="60%" :before-close="handleClose">
      <div slot="title" style="color: #409eff">
        {{ examineForm.status == 1 ? '审批通过意见' : '审批不通过意见' }}
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
      </div>
      <el-form :model="examineForm" ref="dg_formRef" :rules="dg_formRules">
        <el-form-item prop="opinion">
          <el-input
            @change="$forceUpdate()"
            v-model.trim="examineForm.opinion"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          :type="examineForm.status == 1 ? 'success' : 'danger'"
          style="width: 120px"
          @click="addApprove"
          >{{ examineForm.status == 1 ? '审批通过' : '审批不通过' }}</el-button
        >
        <el-button
          type="warning"
          style="margin-right: 37%; margin-left: 12%; width: 120px"
          plain
          @click="handleClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>

    <!-- 财务确认报销弹出框 -->
    <EditDialog :isShow.sync="passDialogFormVisible" @submit="passSubmit">
      <div slot="title" class="title">确认报销</div>
      <div slot="content" class="content">
        <div
          v-if="isInvoiceUsed"
          style="
            display: flex;
            justify-content: center;
            font-weight: 900;
            font-size: 20px;
            color: red;
          "
        >
          <span>此发票号码识别存疑，请核查</span>
        </div>
        <el-form :model="pass_form" label-width="100px">
          <el-form-item label="附件" prop="remark">
            <div class="iconCost">
              <Upload :type="type" :fields="fields" @upload="passUpload"></Upload>
            </div>

            <div
              v-for="(n, index) in pass_form.affirmCostFileList"
              :key="index"
              style="margin-top: 5px"
            >
              <a
                class="fj-text"
                target="_blank"
                style="text-decoration: revert; color: #409eff; padding-right: 8px"
                :href="`${filepath}${n.filePath}`"
              >
                {{ n.fileName }}
              </a>

              <i @click="passDel(index)" class="iconfont iconerror iconCost"></i>
            </div>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="pass_form.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </EditDialog>

    <EditDialog width="60%" ref="IframeDlg" :isShow.sync="printDiaLog">
      <div slot="content" class="content">
        <div id="printArea">
          <template v-for="(n, i) in innerArr">
            <div :key="i">
              <div class="newDate" style="text-align: right">{{ newDate | completeTime }}</div>
              <table border="1">
                <thead>
                  <tr>
                    <th
                      colspan="4"
                      style="
                        font-size: 20px;
                        border-right: 0px;
                        text-align: left;
                        padding-left: 5px;
                      "
                    >
                      外协费用申请单
                    </th>
                    <th colspan="5" style="font-size: 20px; border-left: 0px; border-right: 0px">
                      {{ n.title }}
                    </th>
                    <th colspan="4" style="border-left: 0px; text-align: right; padding-right: 5px">
                      <span
                        >编号:<span>{{ applyForm.expenseNumber }}</span></span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th style="border: 0px; text-align: left" rowspan="1" colspan="3">
                      <span>申请单位:</span
                      ><span style="font-size: 16px">{{
                        applyForm.companyType | dict(companyTypeList)
                      }}</span>
                    </th>
                    <th
                      style="border: 0px; border-left: 2px solid #b3b3b3; text-align: left"
                      colspan="9"
                    >
                      收款单位:<span
                        :style="
                          applyForm.collectionUnit
                            ? applyForm.collectionUnit.length < 35
                              ? 'font-size:16px;'
                              : 'font-size:12px;'
                            : 'font-size:16px;'
                        "
                        >{{ applyForm.collectionUnit }}</span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th style="border: 0px; text-align: left" rowspan="1" colspan="3">
                      <span>报销部门:</span
                      ><span
                        :style="
                          applyForm.deptName
                            ? applyForm.deptName.length < 8
                              ? 'font-size:16px;'
                              : 'font-size:12px;'
                            : 'font-size:16px;'
                        "
                        >{{ applyForm.deptName }}</span
                      >
                    </th>
                    <th
                      style="text-align: left; border: 0px; border-left: 2px solid #b3b3b3"
                      colspan="4"
                    >
                      开户行:<span
                        :style="
                          applyForm.openingBank
                            ? applyForm.openingBank.length < 18
                              ? 'font-size:16px;'
                              : 'font-size:12px;'
                            : 'font-size:16px;'
                        "
                        >{{ applyForm.openingBank }}</span
                      >
                    </th>
                    <th style="text-align: left; border: 0px" colspan="5">
                      银行账号:<span
                        :style="
                          applyForm.bankAccount
                            ? applyForm.bankAccount.length < 24
                              ? 'font-size:16px;'
                              : 'font-size:12px;'
                            : 'font-size:16px;'
                        "
                        >{{ applyForm.bankAccount }}</span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th colspan="13" style="text-align: left">
                      商务名称:<span
                        :style="
                          applyForm.businessName
                            ? applyForm.businessName.length < 50
                              ? 'font-size: 16px !important;'
                              : 'font-size: 0.75rem  !important;'
                            : 'font-size: 16px !important;'
                        "
                        >{{ applyForm.businessName }}</span
                      >
                    </th>
                  </tr>
                  <tr>
                    <th colspan="4"><span>科目明细</span></th>
                    <th colspan="1">
                      <span>{{ n.money }}</span>
                    </th>
                    <th colspan="5"><span>事项说明</span></th>
                    <th colspan="3"><span>付款原因</span></th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="detail" v-for="(item, index) in n.printData" :key="index + i">
                    <td colspan="4">
                      {{ item.typeOneName }}{{ item.typeTwoName ? '-' + item.typeTwoName : ''
                      }}{{ item.typeThreeName ? '-' + item.typeThreeName : '' }}
                    </td>
                    <td>{{ item.cost | applyAmount }}</td>
                    <td
                      colspan="5"
                      :style="
                        item.invoiceStatus
                          ? item.invoiceStatus.length < 40
                            ? 'font-size: 14px !important;'
                            : 'font-size: 12px !important;'
                          : 'font-size: 14px !important;'
                      "
                    >
                      {{ item.invoiceStatus }}
                    </td>
                    <td
                      v-if="index % 5 == 0"
                      colspan="3"
                      rowspan="5"
                      :style="
                        applyForm.paymentCause
                          ? applyForm.paymentCause.length < 180
                            ? 'font-size: 14px !important;'
                            : 'font-size: 12px !important;'
                          : 'font-size: 14px !important;'
                      "
                    >
                      {{ item.paymentCause ? item.paymentCause : applyForm.paymentCause }}
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td style="border: 0px; text-align: left; padding-left: 5px" colspan="3">
                      <span>总合计:</span
                      ><span style="font-size: 18px">{{ amountTo | amountToConvert }}</span>
                    </td>
                    <td colspan="10" style="border: 0px">
                      <span>金额大写:</span
                      ><span style="font-size: 18px">{{ amountTo | numberParseChina }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(item, i) in examineList"
                      :key="i"
                      :colspan="i == 2 ? 3 : i == 3 ? 3 : 2"
                    >
                      {{ item.step }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(item, i) in examineList"
                      :key="i"
                      :colspan="i == 2 ? 3 : i == 3 ? 3 : 2"
                    >
                      {{ item.auditUserName }}
                    </td>
                  </tr>
                  <tr style="height: 1px; border: 0px">
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                    <td style="border: 0px"></td>
                  </tr>
                  <tr>
                    <td style="border: 0px; text-align: left; padding-left: 5px" colspan="4">
                      <span>出纳:</span><span>{{ cashierName ? cashierName : '-' }}</span>
                    </td>
                    <td colspan="6" style="border: 0px">
                      <span>填报日期: </span
                      ><span style="font-size: 16px"> {{ applyForm.taskDate | dateFormat }}</span>
                    </td>
                    <td style="border: 0px; text-align: right; padding-right: 5px" colspan="3">
                      <span>申请人:</span
                      ><span style="font-size: 16px">{{ applyForm.createName }}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div class="outer-table">{{ i + 1 }}/{{ innerArr.length }}</div>
            </div>
          </template>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="success" v-print="print">打印</el-button>
        <el-button type="info" @click="printDiaLog = false">返回</el-button>
      </div>
    </EditDialog>

    <DialogImg
      v-if="dialogVisible"
      :dialogVisible.sync="dialogVisible"
      :filepath="filepath"
      :imgList="imgList"
      :img_index="img_index"
    ></DialogImg>
    <!-- 费用科目说明 -->
    <TypeDialog :isDialogShow.sync="isDialogShow" @changeLoading="handleChangeLoading"></TypeDialog>

    <!-- 历史单位弹窗 -->
    <paymentPartner
      v-if="isPartnerShow"
      @saveFn="getPartnerObj"
      :isShow.sync="isPartnerShow"
    ></paymentPartner>
    <!-- 选择投标 -->
    <SelectBidDialog
      v-if="bidDialog"
      :isShow.sync="bidDialog"
      @onSelectFn="onSelectFn"
      :bidSelectId="bidSelectId"
      @bidCancel="bidDialog = false"
    />
    <!-- 提交意见 -->
    <SubmitOpinionDialog
      v-if="submitDialog"
      :isShow.sync="submitDialog"
      @offsetCancel="submitDialog = false"
      @submitOpinionFn="submitOpinionFn"
    />
  </div>
</template>

<script>
import { dateFormater } from '@/util/common'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    UploadFileToinvoice: () => import('@/components/upload/uploadFileToinvoice.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    TypeDialog: () => import('@/components/typeDialog.vue'),
    paymentPartner: () => import('@/components/payment/paymentPartner.vue'),
    SelectDialog: () => import('@/components/selectDialog.vue'),
    SelectBidDialog: () => import('@/components/bid/selectBidDialog.vue'),
    SubmitOpinionDialog: () => import('@/components/submit-opinion-dialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    getTitle: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    isSee: {
      type: Boolean,
      default: false,
    },
    isApply: {
      type: Boolean,
      default: false,
    },
    isExamine: {
      type: Boolean,
      default: false,
    },
    isGrant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditDialog: false, // 组件 隐藏 或 显示
      loading: false, // 组件 加载
      // 表单 数据
      applyForm: {
        openingBank: '',
        bankAccount: '',
        collectionUnit: '',
      },
      // 审批 表单数据
      examineForm: { opinion: '', status: 0 },
      dg_formRules: {
        opinion: [
          {
            required: true,
            message: '请输入审批不通过意见',
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (this.examineForm.status == 2) {
                if (!value) {
                  callback(new Error('请输入审批不通过意见'))
                } else {
                  callback()
                }
              } else {
                callback()
              }
            },
          },
        ],
      },
      applyFormRules: {
        companyType: [
          {
            required: true,
            message: '请选择申请单位',
            trigger: ['blur', 'change'],
          },
        ],
        collectionUnit: [
          {
            required: true,
            message: '请输入收款单位',
            trigger: ['blur', 'change'],
          },
        ],
        openingBank: [{ required: true, message: '请输入开户行', trigger: ['blur', 'change'] }],
        paymentCause: [{ required: true, message: '请输入付款原因', trigger: 'blur' }],
        bankAccount: [
          {
            required: true,
            validator: this.bankAccount,
            trigger: ['blur', 'change'],
          },
        ],
      },
      selectIdsArr: [],
      businessList: [],
      businessCostRowDeleteList: [],
      businessCostFileDeleteList: [], //删除得附件数组
      //文件上传
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'XMFKSQtype',
      },
      type: 'XMFKSQ',
      isDialog: false,
      paymentType: [],
      affirmCostFileList: [],
      status: 0,
      passDialogFormVisible: false,
      pass_form: {
        projectCostFileList: [],
        remark: '',
      },
      companyTypeList: [],
      rulesTips2: 0,
      rulesTips: 0,
      print: {
        id: 'printArea',
        popTitle: '', // 打印配置页上方的标题
        type: 'html', //打印类型是html
        scanStyles: false,
        targetStyles: ['*'],
      },
      examineList: [],
      cashierName: '',
      printDiaLog: false,
      instInvolved: {}, //当前进度处理人数组
      innerArr: [],
      dialogVisible: false,
      imgList: [],
      temporaryList: [],
      img_index: 0,
      newDate: Date.now(),
      isDialogShow: false,
      typeDialogLoading: false,
      imgUrl: 'https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/科目数据.png',

      isPartnerShow: false, //历史单位弹框
      tableIndex: 0, //明细刷新key
      submitDialog: false, //提交意见弹窗
      bidTable: [], //投标信息
      bidStatus: [], //投标状态
      bidType: [],
      bidSelectId: null,
      delBidManagementId: null,
      bidDialog: false,
    }
  },
  inject: ['re'],
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        this.getInit()
      },
    },
    isShow: {
      immediate: true,
      handler: function (newVal) {
        this.showEditDialog = newVal
        if (newVal) {
          this.$nextTick(() => {
            let scroll = document.getElementById('Scrollbar')
            if (scroll) {
              if (this.options.list_s == 3) {
                setTimeout(() => {
                  scroll.scrollTop = scroll.scrollHeight
                }, 200)
              } else {
                scroll.scrollTop = 0
              }
            }
          })
        }
      },
    },
    printDiaLog: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            let Styles = document.querySelectorAll('.detail')
            var userAgent = navigator.userAgent.toLowerCase()
            let str = userAgent.split('/')
            str = str[3].split(' ')
            str = str[0].split('.')
            Styles.forEach(v => {
              if (userAgent.includes('edg')) {
                v.style.setProperty('--height', '65px')
              } else if (userAgent.includes('chrome')) {
                if (str[0] < 110) {
                  v.style.setProperty('--height', '64px')
                } else {
                  v.style.setProperty('--height', '65px')
                }
              } else {
                v.style.setProperty('--height', '65px')
              }
            })
          })
        }
      },
    },
    temporaryList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          newVal.forEach(v => {
            if (!this.isShowType(v)) {
              this.imgList.push(v)
            }
          })
        }
      },
    },
  },
  created() {
    // this.getInit();
    this.$api.dict
      .listSysDictData('CBFYZC', true)
      .then(res => {
        this.paymentType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.companyTypeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    //获取用户可以申请报销得商务名单
    this.$api.businessOutsourceCost
      .getBusinessNameListByPrincipal()
      .then(res => {
        this.businessList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_STATUS', true)
      .then(res => {
        this.bidStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BID_TYPE', true)
      .then(res => {
        this.bidType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  computed: {
    amountTo() {
      let num = 0
      if (this.applyForm.detailList) {
        this.applyForm.detailList.forEach(v => {
          num += Number(String(v.cost).replace(/,/gi, ''))
        })
      }
      return num
    },
    // 检查明细中发票附件是否已存在使用过
    isInvoiceUsed() {
      if (this.applyForm.detailList) {
        return this.applyForm.detailList.some(v =>
          v.expenseFileList.some(
            item => item.fileType === 'INVOICE' && item.fileInvoiceNoType === 'EXIST'
          )
        )
      }
    },
    openingBankTooltip() {
      if (
        !this.applyForm.openingBank ||
        (this.applyForm.openingBank && this.applyForm.openingBank.length < 15)
      ) {
        return true
      } else {
        return false
      }
    },
    bidIsShow() {
      if (this.applyForm.detailList) {
        const item = this.applyForm.detailList.find(
          v =>
            v.typeOne === 'zhao_tou_biao_fei_yong' ||
            v.typeTwo === 'tou_bao_zheng_jin' ||
            v.typeTwo === 'lv_yue_bao_zheng_jin'
        )
        if (item) {
          return true
        } else {
          return false
        }
      }

      return false
    },
  },
  methods: {
    // 删除
    deleteBid() {
      this.bidTable = []
    },
    // 保证金修改
    onBidEditFn() {
      this.$router.push({
        name: 'QBTBLB',
        params: { data: this.applyForm.bidManagement },
      })
    },
    onSelectFn(row) {
      this.bidSelectId = row.id
      this.bidTable = [{ ...row }]
      this.bidDialog = false
    },
    headerInvoiceClassName({ row, column, rowIndex, columnIndex }) {
      if (column.label === '发票附件上传') {
        return ['headerInvoiceClassName']
      }
      return []
    },
    // 新增审核人
    selectAddAuditor(row) {
      this.$api.addAuditor
        .addTaskSetAssignee({
          userId: row.id,
          processInstId: this.applyForm.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 转审
    selectAuditor(row) {
      this.$api.addAuditor
        .taskSetAssignee({
          userId: row.id,
          processInstId: this.applyForm.processInstId,
        })
        .then(res => {
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          console.log(err)
        })
    },
    blurInput(index) {
      this.applyForm.detailList.forEach((v, i) => {
        if (Number(v.cost) < 0) {
          v.cost = 0
        }
        if (i === index) {
          v.cost = Number(v.cost).toFixed(2)
        }
      })
    },
    async exportFileZipFn() {
      let execute = false
      execute = this.applyForm.detailList.some(v => v.expenseFileList.some(n => !!n))
      if (execute) {
        this.loading = true
        this.$api.common
          .exportFileZip({ documentId: this.applyForm.id })
          .then(res => {
            const blob = new Blob([res], { type: 'application/zip' })
            const fileName = `外协费用申请&${this.applyForm.expenseNumber}&${this.applyForm.id}`
            // const fileName = this.applyForm.id
            if ('download' in document.createElement('a')) {
              // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = window.URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              window.URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
            this.loading = false
            this.$message.success('下载成功')
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.$message.warning('当前单据没有附件可下载')
      }
    },
    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
    imgIsShow(n, index) {
      this.img_index = 0
      this.imgList.forEach((v, i) => {
        if (v.temporaryId) {
          if (v.temporaryId === n.temporaryId) {
            this.img_index = i
          }
        } else if (
          (v.id && n.id && v.id === n.id) ||
          (v.fileName === n.fileName && v.filePath === n.filePath)
        ) {
          this.img_index = i
        }
      })
      this.dialogVisible = true
    },
    async printFn() {
      this.newDate = Date.now()
      this.$nextTick(async () => {
        this.examineList = []
        if (this.$refs.opinionArea && this.$refs.opinionArea.opinionRecordList.length > 1) {
          let examineArr = []
          const arr = this.$refs.opinionArea.opinionRecordList
          await arr.forEach((v, i) => {
            if (v.isCheckPass == 1) {
              v.id = i + Date.now()
              examineArr.push(v)
            }
            if (v.step == '财务支付') {
              this.cashierName = v.auditUserName
            }
          })
          if (examineArr.length == 6) {
            examineArr.unshift({
              id: '132456' + Date.now(),
              step: '财务支付',
              auditUserName: '-',
            })
          }
          let arr2 = examineArr.reduce((filteredArr, item) => {
            const index = filteredArr.findIndex(el => el.step === item.step)
            if (index !== -1) {
              const existingItem = filteredArr[index]
              if (item.auditDate > existingItem.auditDate) {
                filteredArr[index] = item
                if (item.step == '财务支付') {
                  this.cashierName = item.auditUserName
                }
              }
            } else {
              filteredArr.push(item)
            }
            return filteredArr
          }, [])
          this.examineList = arr2.reverse()
          let num = 0
          if (this.examineList.length === 0) {
            num = (this.examineList.length % 5) + 1
          } else {
            num = this.examineList.length % 5
          }
          while (num < 5 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 5
          }
          this.printDiaLog = true
        } else {
          // this.$message.warning('该费用申请单审核流程尚未走完')
          let num = (this.examineList.length % 5) + 1
          while (num < 5 && num !== 0) {
            this.examineList.push({
              id: Date.now() + num,
              step: '',
              auditUserName: '',
            })
            num = this.examineList.length % 5
          }
          this.printDiaLog = true
        }
      })
    },
    affirmCost() {
      this.pass_form = {
        affirmCostFileList: [],
        remark: '',
      }
      this.pass_form.id = this.options.id
      this.passDialogFormVisible = true
    },

    passSubmit() {
      this.loading = true
      this.$api.businessOutsourceCost
        .affirmApply(this.pass_form)
        .then(res => {
          this.loading = false
          this.$message({
            type: 'success',
            message: '成功!',
          })
          this.passDialogFormVisible = false

          this.returnFn()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    passUpload(r) {
      r.forEach(v => {
        if (v) {
          v.type = this.type
        }
      })

      this.pass_form.affirmCostFileList.push(...r)
    },
    passDel(index) {
      this.pass_form.affirmCostFileList.forEach((element, i) => {
        if (i == index) {
          this.pass_form.affirmCostFileList.splice(index, 1)
        }
      })
    },
    exportFn() {
      let time = new Date(this.$refs.opinionArea?.opinionRecordList[0].auditDate).format(
        'yyyy-MM-dd'
      )
      let type = this.$refs.opinionArea?.opinionRecordList[0].type

      let id = ''
      let businessManageId = JSON.stringify(this.applyForm.businessManageId)
      businessManageId = JSON.parse(businessManageId)
      if (businessManageId) {
        id = businessManageId.substr(businessManageId.length - 8)
      }
      let str = `${id}&${type}&${time}&${this.applyForm.createName}&${this.amountTo}元`

      this.$api.businessOutsourceCost
        .exporting(this.applyForm.id)
        .then(res => {
          const fileName = `${str}.xlsx`
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
            this.$message({
              message: '导出成功!',
              type: 'success',
            })
            document.body.removeChild(link) //下载完成移除元素
          }
        })
        .catch(e => {})
    },

    addRedStar(h, { column }) {
      return [h('span', { style: 'color: red' }, '*'), h('span', ' ' + column.label)]
    },
    requiredTwo(dictList, row, index) {
      if (dictList.length > 0) {
        if (index == 2) {
          row.rulesTips = true
          this.$forceUpdate()
        } else if (index == 3) {
          row.rulesTips2 = true
          this.$forceUpdate()
        }
      } else {
        if (index == 2) {
          row.rulesTips = false
        } else if (index == 3) {
          row.rulesTips2 = false
        }
      }
    },
    valueChange(value, row, index) {
      //value表示数据对象返回值，row表示当行内容，index是手动传入
      if (index == 1) {
        // 科目一发生变动，清空科目二科目三内容
        row.rulesTips = false
        row.typeThree = null
        row.typeTwo = null
        row.rulesTips2 = false
      }
      if (index == 2 && row.rulesTips) {
        if (value && row.typeTwo) {
          row.rulesTips = false
        }
      } else if (index == 3 && row.rulesTips2) {
        if (value && row.typeThree) {
          row.rulesTips2 = false
        }
      }
      if (index == 2 && value) {
        // 科目二更换内容
        row.typeThree = null
        row.rulesTips2 = false
      } else if (index == 3 && value) {
        //科目三选中后清除校验
        row.rulesTips2 = false
      }
      this.$forceUpdate()
    },
    rejectFn() {
      this.loading = true
      this.$api.businessOutsourceCost
        .reject({
          id: this.applyForm.id,
        })
        .then(res => {
          this.$message({
            message: '撤回成功!',
            type: 'success',
          })
          this.loading = false
          this.returnFn()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    returnFn() {
      this.re()
      this.$refs.applyForm.resetFields()
      const str = JSON.parse(localStorage.getItem('userInfo')).deptNames.split(',')
      this.applyForm = {
        deptId: JSON.parse(localStorage.getItem('userInfo')).deptIdList[0],
        deptName: str[0],
        createName: JSON.parse(localStorage.getItem('userInfo')).userName,
        applicantId: JSON.parse(localStorage.getItem('userInfo')).staffId,
        taskDate: new Date().getTime(),
        companyType: null,
        collectionUnit: null,
        openingBank: null,
        paymentCause: null,
        bankAccount: null,
      }
      this.status = 0
    },
    /**
     * 审批  申请单
     * @param status     状态 [ 1: 通过, 2: 不通过 ]
     */
    submit() {
      this.loading = true
      let _obj = {
        id: this.applyForm.id,
        opinion: this.examineForm.opinion,
      }
      if (this.examineForm.status == 1) {
        _obj.processInstId = this.applyForm.processInstId
        this.$api.addAuditor
          .disposeTask(_obj)
          .then(res => {
            this.$message({
              message: '操作成功',
              type: 'success',
            })

            this.loading = false
            this.isDialog = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.isDialog = false
          })
      } else if (this.examineForm.status == 2) {
        this.$api.businessOutsourceCost
          .approvalReject(_obj)
          .then(res => {
            this.$message({
              message: '操作成功',
              type: 'success',
            })

            this.loading = false
            this.isDialog = false
            this.returnFn()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.isDialog = false
          })
      }
    },
    //商务去重
    onBusiness() {
      this.selectIdsArr = []
      for (const item of this.applyForm.detailList) {
        if (item.businessManageId) {
          this.selectIdsArr.push(item.businessManageId)
        }
      }
    },
    // 滚动条置底
    handleScrollBottom() {
      this.$nextTick(() => {
        let scroll = document.getElementById('Scrollbar')
        scroll.scrollTop = scroll.clientHeight
        // window.scroll({ top: scroll.clientHeight, left: 0, behavior: 'smooth' });
      })
    },
    // 添加一行
    addRow(index) {
      let obj = {
        cost: 0,
        invoiceStatus: null,
        typeOne: null,
        typeTwo: null,
        typeThree: null,
        rulesTips: false,
        rulesTips2: false,
        expenseFileList: [],
      }
      if (index == 1) {
        this.applyForm.detailList.push(obj)
      } else if (index == 2) {
        if (this.applyForm.detailList.length == 0) {
          this.applyForm.detailList.push(obj)
        } else {
          obj.typeOne = this.applyForm.detailList[this.applyForm.detailList.length - 1].typeOne
          obj.typeTwo = this.applyForm.detailList[this.applyForm.detailList.length - 1].typeTwo
          obj.typeThree = this.applyForm.detailList[this.applyForm.detailList.length - 1].typeThree
          this.applyForm.detailList.push(obj)
        }
      }
    },
    // 删除当前行
    handleRemove(index, row) {
      if (row.id) {
        this.applyForm.detailList[index].deleteFlag = 1
        this.businessCostRowDeleteList.push(row.id)
      }
      this.applyForm.detailList.splice(index, 1)
      row.expenseFileList.forEach(n => {
        this.imgList.forEach((v, i) => {
          if ((v.temporaryId && n.temporaryId && v.temporaryId == n.temporaryId) || v.id == n.id) {
            this.imgList.splice(i, 1)
          }
        })
      })
    },
    uploadIndex(i) {
      this.Index = i //记录当前点击哪个商务
    },
    upload(r) {
      this.temporaryList = []
      r.forEach(v => {
        if (v) {
          v.type = this.type
          v.temporaryId = Date.now()
        }
      })

      this.applyForm.detailList.forEach((v, i) => {
        if (this.Index == i) {
          if (!v.expenseFileList) {
            v.expenseFileList = []
          }
          v.expenseFileList.push(...r)
        }
      })
      this.temporaryList.push(...r)
    },
    del(index, p_index, n) {
      this.applyForm.detailList.forEach((v, key) => {
        if (key == p_index) {
          v.expenseFileList.forEach((k, i) => {
            if (i == index) {
              if (k.id) {
                this.businessCostFileDeleteList.push(k.id)
              }
              v.expenseFileList.splice(i, 1)
            }
          })
        }
      })
      this.imgList.forEach((item, index) => {
        if (
          (item.temporaryId && n.temporaryId && item.temporaryId == n.temporaryId) ||
          item.id == n.id
        ) {
          this.imgList.splice(index, 1)
        }
      })
    },
    invoiceUpload(r) {
      this.upload(r)
    },
    getInit() {
      if (this.options.id) {
        this.getDetails() /** 加载详情数据 **/
      } else {
        const str = JSON.parse(localStorage.getItem('userInfo')).deptNames.split(',')
        this.applyForm = {
          deptId: JSON.parse(localStorage.getItem('userInfo')).deptIdList[0],
          deptName: str[0],
          applicantId: JSON.parse(localStorage.getItem('userInfo')).staffId,
          createName: JSON.parse(localStorage.getItem('userInfo')).userName,
          taskDate: new Date().getTime(),
          // businessOutSource: 'HAVE', //是否商务外部费用  是
          companyType: null,
          collectionUnit: null,
          openingBank: null,
          paymentCause: null,
          bankAccount: null,
          detailList: [
            {
              cost: 0,
              typeOne: null,
              typeTwo: null,
              typeThree: null,
              rulesTips: false,
              rulesTips2: false,
              invoiceStatus: null,
              expenseFileList: [],
            },
          ],
        }
      }
    },

    /** 加载 详情数据 **/
    getDetails() {
      this.applyForm = {}

      this.loading = true
      this.$api.businessOutsourceCost
        .selectDetailById(this.options.id)
        .then(res => {
          if (res.data) {
            this.delBidManagementId = null
            if (res.data.bidManagement) {
              this.bidTable = [{ ...res.data.bidManagement }]
              this.bidSelectId = res.data.bidManagement.id
              this.delBidManagementId = res.data.bidManagement.id
            }
            this.applyForm = res.data
            // this.$refs.applyForm.clearValidate();
            this.loading = false

            this.affirmCostFileList = res.data?.affirmCostFileList

            this.instInvolved = {
              instInvolvedUserName: res.data.instInvolvedUserName
                ? res.data.instInvolvedUserName
                : [],
              status: res.data.status,
            }

            if (res.data.detailList) {
              this.imgList = []
              res.data.detailList.forEach(item => {
                item.expenseFileList.forEach(v => {
                  if (!this.isShowType(v)) {
                    this.imgList.push(v)
                  }
                })
              })
            }
            if (
              this.options.list_s == 3 ||
              this.options.list_s == 4 ||
              this.options.export == 1 ||
              this.applyForm.status > 5
            ) {
              this.innerArr = []
              const printData = []
              const arr = []
              const multipleArr = []
              this.applyForm.detailList.forEach((v, i) => {
                Promise.all([
                  this.$api.dict.listSysDictData('CBFYZC', true),
                  this.$api.dict.listSysDictData(v.typeOne, true),
                  this.$api.dict.listSysDictData(v.typeTwo, true),
                ])
                  .then(async ([typeOne, typeTwo, typeThree]) => {
                    const arrOne = await typeOne.data.filter(item => item.dictVal == v.typeOne)
                    const arrTwo = await typeTwo.data.filter(item => item.dictVal == v.typeTwo)
                    const arrThree = await typeThree.data.filter(
                      item => item.dictVal == v.typeThree
                    )
                    v.typeOneName = arrOne[0].dictName
                    v.typeTwoName = arrTwo[0].dictName
                    v.typeThreeName = arrThree[0].dictName
                  })
                  .catch(err => {})
                arr.push(v)
                multipleArr.push(v)
              })
              let num = arr.length % 5
              while (num < 5 && num !== 0) {
                arr.push({
                  typeOneName: '',
                  typeTwoName: '',
                  typeThreeName: '',
                  cost: '',
                  invoiceStatus: '',
                })
                num = arr.length % 5
              }
              const batchSize = 5 // 每个子数组的大小
              for (let i = 0; i < arr.length; i += batchSize) {
                printData.push(arr.slice(i, i + batchSize))
              }
              // this.printIndex = this.printData.length
              let keyArr = []
              this.$api.dict.listSysDictData('CBFYZC', true).then(async res => {
                keyArr = res.data
                printData.forEach(item => {
                  let innerObj = {
                    title: '外协费用申请单',
                    money: '金额',
                    printData: [],
                  }
                  item.forEach(v => {
                    innerObj.amountToMoney += Number(v.cost)
                  })
                  innerObj.printData = item
                  this.innerArr.push(innerObj)
                })
                // 财务要求，只要科目一的所有明细同样即不需汇总单
                let multiple = false
                if (this.applyForm.detailList && this.applyForm.detailList.length > 0) {
                  multiple = multipleArr.some(
                    v => v.typeOne !== this.applyForm.detailList[0].typeOne
                  )
                }
                if (printData.length > 1 && multiple) {
                  // 汇总的
                  const allArr = []
                  const temporaryArr = []
                  this.applyForm.detailList.forEach(v => {
                    keyArr.forEach(k => {
                      if (v.typeOne === k.dictVal) {
                        allArr.push({
                          typeOneName: k.dictName + '(汇总)',
                          typeTwoName: '',
                          typeThreeName: '',
                          cost: v.cost,
                          invoiceStatus: '本次申请合计',
                          paymentCause: '本次申请合计',
                        })
                      }
                    })
                  })
                  const result = []
                  for (let i = 0; i < allArr.length; i++) {
                    let found = false
                    for (let j = 0; j < result.length; j++) {
                      if (result[j].typeOneName === allArr[i].typeOneName) {
                        result[j].cost += allArr[i].cost
                        found = true
                        break
                      }
                    }

                    if (!found) {
                      result.push(allArr[i])
                    }
                  }
                  let arrNum = result.length % 5
                  while (arrNum < 5 && arrNum !== 0) {
                    result.push({
                      typeOneName: '',
                      typeTwoName: '',
                      typeThreeName: '',
                      cost: '',
                      invoiceStatus: '',
                    })
                    arrNum = result.length % 5
                  }
                  const allSize = 5 // 每个子数组的大小
                  for (let i = 0; i < result.length; i += allSize) {
                    temporaryArr.push(result.slice(i, i + allSize))
                  }
                  temporaryArr.forEach(item => {
                    this.innerArr.push({
                      title: '外协费用申请单(汇总)',
                      money: '合计',
                      printData: item,
                    })
                  })
                }
              })
            }
          }

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    /** 点击 "提交==1" 暂存==0 按钮 **/
    handleSubmit(status) {
      //处理验证逻辑，status为1则是提交
      this.status = status
      this.applyForm.detailDeleteList = this.businessCostRowDeleteList
      this.applyForm.fileDeleteList = this.businessCostFileDeleteList
      if (this.bidTable && this.bidTable.length > 0) {
        // 新增
        if (this.bidIsShow) {
          this.applyForm.bidManagementId = this.bidTable[0].id
        }
        // 有id
        if (this.delBidManagementId) {
          // 没有更换投标
          if (this.delBidManagementId === this.bidTable[0].id) {
            this.applyForm.delBidManagementId = null
          }
          // 编辑更换投标
          if (this.applyForm.bidManagement.id !== this.bidTable[0].id) {
            this.applyForm.bidManagementId = this.bidTable[0].id
            this.applyForm.delBidManagementId = this.delBidManagementId
          }
        }
        // 关联后移除
        if (!this.bidIsShow) {
          this.applyForm.delBidManagementId = this.bidTable[0].id
        }
      }
      // 有id 并且再移除关联
      if (this.delBidManagementId && this.bidTable.length <= 0) {
        this.applyForm.delBidManagementId = this.delBidManagementId
      }
      if (this.status == 1) {
        if (this.bidIsShow && this.bidTable.length <= 0) {
          return this.$message.warning('请关联投标信息')
        }
        let ruleTips = false
        this.applyForm.detailList.forEach(m => {
          if (
            !this.applyForm.businessManageId ||
            !m.typeOne ||
            !m.cost ||
            !m.invoiceStatus ||
            (!m.typeThree && m.rulesTips2) ||
            (!m.typeTwo && m.rulesTips)
          ) {
            ruleTips = true
            this.$forceUpdate()
          }
          if (m.rulesTips) {
            this.rulesTips = 1
          } else {
            this.rulesTips = 0
          }
          if (m.rulesTips2) {
            this.rulesTips2 = 1
          } else {
            this.rulesTips2 = 0
          }
        })
        if (ruleTips) {
          ++this.tableIndex
          this.$forceUpdate()
          return
        }

        let amount = this.amountTo
        if (amount <= 0) {
          this.$message('申请总金额不能为0')
          return
        }

        this.$refs.applyForm.validate((valid, obj) => {
          if (valid) {
            this.loading = true
            this.submitDialog = true
            this.loading = false
          } else {
            this.scrollView(obj)
          }
        })
      } else {
        this.loading = true
        // todo 暂存
        //* 如果有id且当前状态是暂存，则走暂存编辑接口
        if (this.applyForm.id) {
          this.$api.businessOutsourceCost
            .undeterminedEdit(this.applyForm)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        } else {
          this.$api.businessOutsourceCost
            .tempAdd(this.applyForm)
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('操作成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log('错误：', err)
            })
        }
      }
    },
    // 提交意见
    submitOpinionFn(opinion) {
      this.applyForm.opinion = opinion
      this.loading = true
      // 判断当前单据1状态是否为0且包含流程id，是的话说明该单据是撤回的，走重新提交接口
      if (
        (this.applyForm.status == 0 && this.applyForm.processInstId) ||
        (this.applyForm.status == 5 && this.applyForm.processInstId)
      ) {
        this.$api.businessOutsourceCost
          .resubmitByReject(this.applyForm)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      } else {
        this.$api.businessOutsourceCost
          .startProcess(this.applyForm)
          .then(res => {
            this.loading = false
            this.showEditDialog = false
            this.$message.success('操作成功！')
            this.re()
          })
          .catch(err => {
            this.loading = false
            console.log('错误：', err)
          })
      }
      this.submitDialog = false
    },

    /** 点击 "通过" 按钮 **/
    handleAdopt() {
      this.examineForm = {
        opinion: '',
        status: 0,
      }
      this.examineForm.status = 1
      this.isDialog = true
    },

    /** 点击 "不通过" 按钮 **/
    handleFail() {
      this.examineForm = {
        opinion: '',
        status: 0,
      }
      this.examineForm.status = 2
      this.isDialog = true
    },

    handleClose() {
      this.isDialog = false
      this.$refs.dg_formRef?.resetFields()
      this.examineForm.opinion = ''
    },

    addApprove() {
      this.$refs.dg_formRef.validate(valid => {
        if (valid) {
          if (this.examineForm.status == 1) {
            if (this.examineForm.opinion == '') {
              this.examineForm.opinion = '通过'
            }
          }
          this.submit()
        }
      })
    },
    inputFn(key) {
      this.applyForm[key] = this.applyForm[key].replace(/\s*/g, '')
      var result = []
      for (var i = 0; i < this.applyForm[key].length; i++) {
        if (i % 4 == 0 && i != 0) {
          result.push(' ' + this.applyForm[key].charAt(i))
        } else {
          result.push(this.applyForm[key].charAt(i))
        }
      }
      this.applyForm[key] = result.join('')
      this.$forceUpdate()
    },
    bankAccount(rule, value, callback) {
      if (!value) {
        callback(new Error('请输入银行账号'))
      } else {
        const reg = /^\d{7,30}$/ //通用银行账号正则表达式
        value = value.replace(/\s/g, '')
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的银行账号'))
        }
      }
    },
    handleChangeLoading(e) {
      this.typeDialogLoading = e
    },

    //获取弹框返回内容
    getPartnerObj(e) {
      const { openingBank, bankAccount, collectionUnit } = e
      this.applyForm.openingBank = openingBank
      this.applyForm.bankAccount = bankAccount
      this.applyForm.collectionUnit = collectionUnit
      this.isPartnerShow = false
      this.$nextTick(() => {
        this.$refs.applyForm?.clearValidate(['collectionUnit', 'bankAccount', 'openingBank'])
      })
    },
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return ''
      }
    },
    amountToConvert(n) {
      if (!n) return
      n = Number(n).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return n
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';

.form_box {
  margin: 20px 0;
  .danwei {
    position: absolute;
    right: 2%;
  }

  .amount {
    width: 100%;
    height: 40px;
    background-color: #f5f7fa;
    padding: 0 10px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    white-space: nowrap;
  }
}

.el-divider__text.is-left {
  font-size: 18px;
}

.teal_bg {
  background-color: #014d67;
  border: 1px solid #014d67;
}

.teal_bg:hover {
  opacity: 0.8;
}
/deep/ .el-table .cell {
  white-space: pre-line;
  width: 100% !important;
  #el-cascader {
    .el-cascader {
      width: 100%;
    }
  }
}
.row_css {
  font-size: 14px;
  color: #909399;
  vertical-align: inherit;
  padding-left: 5px;
}
.addButton {
  margin-bottom: 10px;
}

table {
  width: 100%;
}
td {
  width: 25vh;
}

b {
  font-size: 19px;
  color: black;
}

.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}

.bg_div {
  border: 1px solid #eee;
  margin-bottom: 10px;
  width: 100%;
}

.color_r {
  color: #f00;
}
/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  padding: 8px 10px;
}
/deep/.el-descriptions-item__content {
  padding: 8px 10px;
}
/deep/.el-form-item {
  margin-bottom: 12px;
}

/deep/.outsourceLabelClassName {
  width: 16.6%;
}
/deep/.outsourceContentClassName {
  height: 75px !important;
}

/deep/.businessOutSource {
  //是否为外协费用背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}

#printArea {
  font-family: '微软雅黑';
  width: 1000px;
}
.outer-table {
  display: none;
  text-align: right;
  font-size: 16px !important;
}
#printArea table {
  word-break: break-all !important;
  font-size: 12px !important;
  .detail {
    text-align: center;
    height: var(--height);
    line-height: 1;
  }
  thead {
    tr {
      height: 35px;
    }
  }
  tfoot {
    text-align: center;
    tr {
      height: 35px;
    }
  }
}
@media print {
  @page {
    margin: 8mm 10mm;
    size: landscape; //横向
  }

  tbody {
    page-break-inside: avoid; /* 避免将tbody分割在多个页面上 */
    //   /* 根据需要在第四条数据之后进行分页处理 */
  }
  .outer-table {
    display: block;
  }
}
// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
.dictionary {
  padding-right: 0;
  padding-bottom: 0;
}
// 附件编码
.invoice-file {
  display: flex;
  margin-top: 5px;
  .invoice-file-left {
    width: 85%;
  }
}
/deep/.headerInvoiceClassName {
  background-color: #b2f3fe !important;
}
</style>
